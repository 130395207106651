import {
  Badge,
  Box,
  Flex,
  Heading,
  Image,
  Input,
  LinkBox,
  LinkOverlay,
  Select,
  Text,
} from '@chakra-ui/react';
import ContentTypeBadge from 'business-dashboard/src/components/businesses/social/components/ContentTypeBadge';
import NetworkIcon from 'business-dashboard/src/components/businesses/social/components/NetworkIcon';
import StatusBadge from 'business-dashboard/src/components/businesses/social/components/StatusBadge';
import Post from 'business-dashboard/src/components/businesses/social/interfaces/Post.interface';
import React from 'react';
import { Link } from 'react-router-dom';
import usePagedList from 'shared/src/hooks/usePagedList';
import {
  useQuerystringParam,
  transport,
} from 'shared/src/hooks/useQuerystringParam';
import ErrorAlert from 'web-react-ui/src/chakra/ErrorAlert';
import { AutoPagination } from 'web-react-ui/src/chakra/Pagination';
import {
  Empty,
  TableCell,
  TableList,
  TableRow,
} from 'web-react-ui/src/chakra/TableList/TableList';
import client from '../../services/client';

const fetchPosts = async ({ state, startDate, endDate }: {
  state: string,
  startDate?: string,
  endDate?: string
}): Promise<{ items: Array<Post> } | undefined> => {
  const params: { state: string, rangeStart?: string, rangeEnd?: string } = { state };
  if (startDate && endDate) {
    // Round-trip dates so they're the right format for our API
    params.rangeStart = new Date(startDate).toISOString();
    params.rangeEnd = new Date(endDate).toISOString();
  }
  return client
    .social.posts.listAll(params);
};

const postColumns = [
  {
    key: 'content',
    label: 'Content',
    cell: {
      w: '100%',
    },
    skeletonCircle: {
      boxSize: '6em',
    },
    skeletonText: {
      noOfLines: 2,
      w: '100%',
    },
  },
  {
    key: 'date',
    label: 'Scheduled Date',
    cell: {
      minW: '12rem',
    },
    skeletonText: {
      noOfLines: 1,
    },
  },
  {
    key: 'status',
    label: 'Status',
    cell: {
      minW: '8rem',
      textAlign: 'right',
    },
    skeletonText: {
      noOfLines: 1,
    },
  },
];

const PostItem = ({ post }: { post: Post }) => {
  const businessId = post.relatedEntities.find(e => e.type === 'business')?.id;

  return (
    <LinkBox as={TableRow}>
      <TableCell column={postColumns[0]}>
        <Flex gap="1em" align="center">
          <Box position="relative" flexShrink={0}>
            <Box position="absolute" top="-0.5em" right="-0.5em" fontSize="16px" color="#4267B2">
              <NetworkIcon id={post.socialNetwork} fontSize="1.5em" />
            </Box>
            <Image src={post.output.media?.[0]} boxSize="6em" minW="6em" bg="#eee" />
          </Box>
          <Flex direction="column">
            <LinkOverlay as={Link} to={`/businesses/${businessId}/features/post/${post.id}`}>
              <Flex gap="1em" align="center">
                <ContentTypeBadge type={post.contentType} />
                <Text fontWeight="bold">{post.campaignName}</Text>
              </Flex>
            </LinkOverlay>
            <Text noOfLines={1}>{post.output.message}</Text>
            <Link to={`/businesses/${businessId}/features`}>Business {businessId}</Link>
          </Flex>
        </Flex>
      </TableCell>
      <TableCell column={postColumns[1]}>
        <Text whiteSpace="nowrap">{post.scheduledDate}</Text>
      </TableCell>
      <TableCell column={postColumns[2]}>
        <StatusBadge status={post.displayState} />
      </TableCell>
    </LinkBox>
  );
};

const SocialView = () => {
  const [postState, setPostState] = useQuerystringParam('error', 'error', { squash: true });
  const [startDate, setStartDate] = useQuerystringParam('start', undefined, {
    transport: transport.dateString,
    squash: true,
  });
  const [endDate, setEndDate] = useQuerystringParam('end', undefined, {
    transport: transport.dateString,
    squash: true,
  });

  const postList = usePagedList(
    fetchPosts,
    { state: postState, startDate, endDate },
  );

  return (
    <Flex gap="1em" direction="column" maxW="7xl" mx="auto" px={[2, 0]} my={10}>
      <Heading>Social AI</Heading>
      <Flex align="center" gap="2em" w="100%">
        <Flex align="center" gap="1em">
          <Input type="date" value={startDate} onChange={e => setStartDate(e.target.value)} />
          <Badge borderRadius="full">to</Badge>
          <Input type="date" value={endDate} onChange={e => setEndDate(e.target.value)} />
        </Flex>
        <Select onChange={e => setPostState(e.target.value)} value={postState}>
          <option value="scheduled">Scheduled</option>
          <option value="live">Live</option>
          <option value="missing">Missing</option>
          <option value="error">Error</option>
        </Select>
      </Flex>
      <TableList columns={postColumns} isLoading={postList.loading}>
        <ErrorAlert error={postList.error} />
        <Empty isEmpty={postList.empty}>
          <Text fontSize="xl">No Posts To Display</Text>
        </Empty>
        {postList.items.map((post: Post) => (
          <PostItem key={post.id} post={post} />
        ))}
      </TableList>
      <AutoPagination pagedList={postList} />
    </Flex>
  );
};

export default SocialView;
