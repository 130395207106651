import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Text } from '@chakra-ui/react';
import {
  NavLink,
  Redirect,
  Route,
  Switch
} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { getLabelForType } from 'shared/src/constants/socialProfiles';

import ActionMenu from 'web-react-ui/src/components/actionMenu/ActionMenu';
import Label from 'web-react-ui/src/components/elements/Label';
import View from 'web-react-ui/src/components/layout/View';
import WaitFor from 'web-react-ui/src/data/WaitFor';
import SubNav from 'web-react-ui/src/navigation/SubNav';

import AdminBusinessAddLocationContainer from '../components/admin/AdminBusinessAddLocationContainer';
import AdminBusinessLocationDetails from '../components/admin/AdminBusinessLocationDetails';
import AdminBusinessLocationsContainer from '../components/admin/AdminBusinessLocationsContainer';
import AdminBusinessUsers from '../components/admin/AdminBusinessUsers';
import AdminFeatures from '../components/admin/features/AdminFeatures';
import EditBusinessModal from '../components/businesses/EditBusinessModal';
import businessModule from '../modules/businesses/index';
import client from '../services/client';

const NavMenuItem = props => <SubNav.Item as={NavLink} {...props} />;

const getPortalUrl = (businessId) => {
  const env = process.env.REACT_APP_ENV;
  const portalBaseUrl = env === 'prod'
    ? 'https://portal.loopmediagroup.ca'
    : 'https://portal.lmg-dev.ca';
  const url = new URL(portalBaseUrl);
  url.pathname = `/${process.env.REACT_APP_DEFAULT_PROPERTY}/businesses/${businessId}`;
  return url.href;
};

const getChargebeeUrl = (businessId) => {
  /**
   * Chargebee's view filters are a Base64-encoded JSON object at search.filters.
   * Unfortunately, custom fields are indexed in order of creation, so we have to use a different
   * "operand" for our Production and Test sites on Chargebee.
   * (eg: on prod, the `cf_business_id` field is identified by `custom_fields[string_1]`)
   */
  const env = process.env.REACT_APP_ENV;
  const chargebeeBaseUrl = env === 'prod'
    ? 'https://getinthelooplocal.chargebee.com'
    : 'https://getinthelooplocal-test.chargebee.com';
  const url = new URL(chargebeeBaseUrl);
  url.pathname = `/customers`;
  const filter = [{
    operand: env === 'prod'
      ? 'custom_fields[string_1]'
      : 'custom_fields[string_2]',
    operator: 'equals',
    type: 'cb_text',
    value: businessId
  }];
  url.searchParams.set('filters', btoa(JSON.stringify(filter)));
  return url.href;
};

/**
 * Sadly, this is the best we can do without a backend function connected to Xero.
 * We can't even pre-fill the search terms.
 * We just direct you to the appropriate Xero instance depending on the billing address.
 */
const getXeroUrl = (business) => {
  if (business?.billingAddress?.country === 'US') {
    return 'https://go.xero.com/app/!VBBX1/contacts/customers';
  }
  return 'https://go.xero.com/app/!mT5t9/contacts/customers';
};

class AdminBusinessDetailsRoutes extends Component {
  static propTypes = {
    baseUrl: PropTypes.string,
    business: PropTypes.object,
    businessId: PropTypes.string,
    match: PropTypes.any,
    property: PropTypes.object,
    details: PropTypes.func,
    invalidateAndRefresh: PropTypes.func,
    invalidate: PropTypes.func
  };

  state = {};

  componentDidMount() {
    this.props.details({
      businessId: this.props.businessId
    });
  }

  componentWillUnmount() {
    this.props.invalidate();
  }

  editBusiness() {
    this.setState({ editBusiness: true });
  }

  cancelEditBusiness() {
    this.setState({ editBusiness: false });
  }

  // TODO: Move this inside EditBusinessModal
  saveBusiness(newFields) {
    const { business, invalidateAndRefresh } = this.props;
    return client
      .businesses.for(business.id)
      .update(business, newFields)
      .then(() => {
        invalidateAndRefresh({
          businessId: business.id,
        });
      })
      .then(() => this.setState({ editBusiness: false }));
  }

  // eslint-disable-next-line class-methods-use-this
  updateLocationAddress(address) {
    return client
      .addresses.sync(address);
  }

  render() {
    const { baseUrl, business } = this.props;
    const { editBusiness } = this.state;

    const actions = [
      {
        label: 'Edit',
        action: this.editBusiness.bind(this)
      },
      {
        label: (<div>Open in Portal <ExternalLinkIcon /></div>),
        extraProps: {
          as: 'a',
          href: getPortalUrl(business?.id),
          target: '_blank'
        }
      },
      {
        label: (<div>Find in Chargebee <ExternalLinkIcon /></div>),
        extraProps: {
          as: 'a',
          href: getChargebeeUrl(business?.id),
          target: '_blank'
        }
      },
      {
        label: (<div>Search in Xero <ExternalLinkIcon /></div>),
        extraProps: {
          as: 'a',
          href: getXeroUrl(business),
          target: '_blank'
        }
      }
    ];

    return (
      <WaitFor waitFor={business}>
        {editBusiness && <EditBusinessModal
          open={editBusiness}
          business={business}
          onCancel={this.cancelEditBusiness.bind(this)}
          onSubmit={this.saveBusiness.bind(this)}
        />}
        <View className="bg--gray brdr--b">
          <View.Section>
            <div className="pt2 mb2">
              <div className="pull-right">
                <ActionMenu actions={actions} />
              </div>
              <h3 className="mt0">{business && business.name}</h3>
              <Label.Group>
                {business && business.categories.map(category => <Label key={category.id}>{category.name}</Label>)}
              </Label.Group>
              {
                business?.socialProfiles?.map((p, index) => (
                  <p key={index}>
                    <strong>{getLabelForType((p.type))}</strong>: {p.id}
                  </p>
                ))
              }
            </div>
          </View.Section>
          <View.Section className="p0">
            <div className="mt2">
              <SubNav>
                <NavMenuItem to={`${baseUrl}/locations`} name="locations" />
                <NavMenuItem to={`${baseUrl}/users`} name="users" />
                <NavMenuItem to={`${baseUrl}/features`} name="features" />
              </SubNav>
            </div>
          </View.Section>
        </View>
        <Switch>
          <Route
            path={`${baseUrl}/locations/add`}
            render={() => <AdminBusinessAddLocationContainer />}
          />
          <Route
            path={`${baseUrl}/locations/:locationId`}
            render={props => <AdminBusinessLocationDetails
              locationId={props.match.params.locationId}
              businessId={this.props.businessId}
              updateLocationAddress={this.updateLocationAddress.bind(this)}
            />}
          />
          <Route
            path={`${baseUrl}/locations`}
            render={props => <AdminBusinessLocationsContainer basePath={props.match.url} />}
          />
          <Route
            path={`${baseUrl}/users`}
            render={props => <AdminBusinessUsers basePath={props.match.url} />}
          />
          <Route path={`${baseUrl}/features`}>
            <AdminFeatures business={business} />
          </Route>
          <Redirect to={`${baseUrl}/locations`} />
        </Switch>
      </WaitFor>
    );
  }
}

const mapState = state => ({
  business: businessModule.selectors.business.getData(state)
});

const mapDispatch = (dispatch) => {
  return bindActionCreators(businessModule.actions, dispatch);
};


export default connect(mapState, mapDispatch)(AdminBusinessDetailsRoutes);
